import {
  Button,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import AceEditor from "react-ace";
import ReactDiffViewer from "react-diff-viewer";
import { format } from "date-fns";

import { fetchWithToken } from "../../hooks/useFetch";
import { useAuth } from "src/contexts/auth";
import { BusyOverlay } from "src/components/BusyOverlay";

import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/ext-language_tools";

type Calibration = {
  revision: number;
  revisionNote: string;
  timeCreated: number;
  calibrationFile: string;
};

type CalibrationViewerProps = {
  instrumentID: string;
  serialNumber: string;
  tenant: string;
  onClose: () => void;
};

const CalibrationViewer = ({
  instrumentID,
  serialNumber,
  tenant,
  onClose,
}: CalibrationViewerProps) => {
  const [compareVersions, setCompareVersions] = useState(false);
  const { getAccessTokenSilently } = useAuth();
  const [isReady, setIsReady] = useState(false);
  const [calibrationHistory, setCalibrationHistory] = useState<Calibration[]>(
    []
  );
  const [selectedCalibrationHistoryIndex, setSelectedCalibrationHistoryIndex] =
    useState(-1);

  useEffect(() => {
    const url = `/instrument/${instrumentID}/calibrationFiles?serialNumber=${serialNumber}&tenant=${tenant}`;
    fetchWithToken(getAccessTokenSilently, url, {
      method: "GET",
    })
      .then((results) => {
        setCalibrationHistory(results);
        if (results.length > 0) {
          setSelectedCalibrationHistoryIndex(0);
        }
        setIsReady(true);
      })
      .catch((error) => {
        setIsReady(true);
        alert(error.message);
      });
  }, [instrumentID, serialNumber, tenant, getAccessTokenSilently]);

  const yaml = useMemo(
    () =>
      calibrationHistory.length > 0
        ? calibrationHistory[0].calibrationFile
        : "",
    [calibrationHistory]
  );

  const compareToYaml = useMemo(() => {
    if (selectedCalibrationHistoryIndex < 0) return "";
    if (
      !calibrationHistory ||
      calibrationHistory.length <= selectedCalibrationHistoryIndex
    )
      return "";
    return calibrationHistory[selectedCalibrationHistoryIndex].calibrationFile;
  }, [calibrationHistory, selectedCalibrationHistoryIndex]);

  const msg =
    calibrationHistory.length === 0 && isReady
      ? "This instrument has no calibration files stored in the cloud database"
      : "";
  return (
    <Box sx={{ height: "100%" }}>
      <Box sx={{ display: "flex", mt: 1 }}>
        <Button sx={{ ml: 1 }} variant="contained" onClick={() => onClose()}>
          {" "}
          Back
        </Button>
        <Typography color="error" variant="caption" sx={{ ml: 10, mt: 2 }}>
          {msg}
        </Typography>
        <FormControlLabel
          sx={{ ml: "auto" }}
          control={
            <Switch
              checked={compareVersions}
              onClick={() => setCompareVersions((v) => !v)}
            />
          }
          label="Compare Versions"
        />
      </Box>
      <Box
        sx={{
          mt: 1,
          border: "1px solid #ccc",
          height: "100%",
        }}
      >
        <AceEditor
          mode="yaml"
          theme="textmate"
          readOnly
          value={yaml}
          name="calibration_yaml"
          editorProps={{
            $blockScrolling: true,
          }}
          setOptions={{
            showPrintMargin: false,
            highlightActiveLine: false,
          }}
          width="100%"
          height={compareVersions ? "50%" : "100%"}
        />
        {compareVersions && (
          <Box height="50%">
            <Box
              sx={{
                display: "flex",
                borderBottom: "solid lightGray",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  mt: 2,
                  height: "45px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ mr: 2, ml: 1 }}>Version</Typography>
                <FormControl variant="outlined" size="small">
                  <Select
                    value={selectedCalibrationHistoryIndex}
                    onChange={(e) =>
                      setSelectedCalibrationHistoryIndex(
                        e.target.value as number
                      )
                    }
                    sx={{ width: "250px", textAlign: "start" }}
                  >
                    {calibrationHistory
                      .sort(
                        (a, b) =>
                          ((b.timeCreated as any) - a.timeCreated) as any
                      )
                      .map((v, i) => {
                        const dt = new Date(v.timeCreated * 1000);
                        const dtString = format(dt, "yyyy-MM-dd HH:mm:ss");

                        return (
                          <MenuItem
                            key={v.revision}
                            value={i}
                          >{`Rev ${v.revision} 
                        ${dtString} `}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <Button
                  onClick={() => {
                    const element = document.createElement("a");
                    const file = new Blob([compareToYaml], {
                      type: "text/plain",
                    });
                    element.href = URL.createObjectURL(file);
                    element.download = `calibration-${serialNumber}-${calibrationHistory[selectedCalibrationHistoryIndex].revision}.txt`;
                    document.body.appendChild(element); // Required for this to work in FireFox
                    element.click();
                    document.body.removeChild(element); // Clean up after click
                  }}
                >
                  Export
                </Button>
                <Typography sx={{ ml: 2 }} variant="caption">
                  {selectedCalibrationHistoryIndex >= 0
                    ? `Revision Note: ${calibrationHistory[selectedCalibrationHistoryIndex]?.revisionNote}`
                    : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  mt: 2,
                  height: "45px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography>
                  Lateset Revision{" "}
                  {calibrationHistory.length > 0
                    ? `(Rev ${calibrationHistory[0].revision})`
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: 12,
                ".css-1n5o7vh-diff-container pre": { lineHeight: "14px" },
                height: "100%",
                overflow: "scroll",
              }}
            >
              <ReactDiffViewer
                oldValue={compareToYaml}
                newValue={yaml}
                splitView
                //  renderContent={highlightSyntax}
              />
            </Box>
          </Box>
        )}
      </Box>

      {!isReady && <BusyOverlay />}
    </Box>
  );
};

export default CalibrationViewer;
