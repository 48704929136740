import { useRef, memo, useMemo, useCallback, useState, useEffect } from "react";
import DataGrid, {
  Column,
  Lookup,
  Grouping,
  GroupPanel,
  Export,
  ColumnChooser,
  Paging,
  SearchPanel,
  Summary,
  Format,
  GroupItem,
  FilterRow,
  Pager,
  Editing,
  Item,
  Selection,
  HeaderFilter,
  Toolbar,
  StateStoring,
} from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";

import { Link } from "react-router-dom";

import CustomStore from "devextreme/data/custom_store";
import { fetchWithToken } from "src/hooks/useFetch";
import { useAuth } from "src/contexts/auth";
import { gradeMetricForRun } from "src/utils/run-grading";

import kits from "./sequencing-kits.json";

const fields = [
  { name: "Last Month", value: 1 },
  { name: "Last 3 Months", value: 3 },
  { name: "Last 6 Months", value: 6 },
  { name: "Last Year", value: 12 },
  { name: "All", value: 0 },
];

const sequencingKitLookup = (pn) => {
  const obj = kits.find((v) => v.partNumber === pn);
  if (obj) return obj.name;
  return "N/A";
};

const targetedReadsLookup = (pn) => {
  const obj = kits.find((v) => v.partNumber === pn);
  if (obj) return obj.targetedReads;
  return "N/A";
};

const groupCellTemplate = (elem, data) => {
  var formattedValue = `${data.displayValue} (${data.data.aggregates} runs)   ${
    data.groupContinuesMessage ? `  (${data.groupContinuesMessage})` : ""
  }`;
  elem.append(formattedValue);
};

const pagesizes = [20, 100, "all"];

const colfixing = { enabled: true };

const customizedText = (e) => {
  if (isNaN(e.value) || e.value <= 0) return "";
  return e.valueText;
};

const getRecipeBundle = (e) => {

  if (e.value) {
    const recipeValuesSplit = e.value.split("^^^");
    for (const value of recipeValuesSplit) {
      const splitValue = value.split("###");
      if (splitValue[0] === 'RecipesBundleName') return splitValue[1]
    }
  }

  return "";
}

const customizedDateText = (e) => {
  if (e.value.getFullYear() < 2020) return "";
  return e.valueText;
};

const cellRenderRunName = (cell) => {
  if (!cell.value) {
    return <div />;
  }
  return <Link to={"/runs?r=" + cell.data.runID + "&t=0"}>{cell.text}</Link>;
};

export default memo((props) => {
  const { getAccessTokenSilently } = useAuth();
  const grid = useRef();
  const [dispositions, setDispositions] = useState([]);
  const [numMonths, setNumMonths] = useState(1);

  const loadData = useCallback(async () => {
    const runs = await fetchWithToken(getAccessTokenSilently, "/run?customer&numMonths=" + numMonths);

    console.log(runs);
    
    if (!runs) return [];
    const vals = runs;
    if (!vals) return [];

    const tenantMap = new Map();

    let successes = 0;
    let failures = 0;
    let optimizations = 0;
    let notDispositioned = 0;
    for (let i = 0; i < vals.length; i++) {
      const tenantName = vals[i].tenantID.replaceAll("ten_", "");
      if (!tenantMap.get(tenantName))
        tenantMap.set(tenantName, {
          runs: 0,
          success: 0,
          failed: 0,
          failedOptimization: 0,
          notDispositioned: 0,
        });
      const tenantEntry = tenantMap.get(tenantName);
      tenantEntry.runs++;
      vals[
        i
      ].runLength = `${vals[i].r1}+${vals[i].r2},${vals[i].i1}+${vals[i].i2}`;

      vals[i].SequencingKit = sequencingKitLookup(vals[i].reagentPartNumber);
      vals[i].TargetedReads = targetedReadsLookup(vals[i].reagentPartNumber);

      var dt = new Date(vals[i].Date);
      dt.setHours(dt.getHours() + 48);

      const isOld = new Date().getTime() > dt.getTime();
      const runCompleted =
        ["Finished", "Failed"].includes(vals[i].lastStatus) || isOld;

      vals[i].currentlyRunning = !runCompleted;

      const partNumber = vals[i].reagentPartNumber;

      if (runCompleted) {
        const passed =
          gradeMetricForRun("q30", vals[i].q30, partNumber) === "green" &&
          gradeMetricForRun("poloniesPF", vals[i].poloniesPF, partNumber) ===
            "green" &&
          gradeMetricForRun("runTime", vals[i].runTime, partNumber) !== "red" &&
          gradeMetricForRun(
            "indexAssignmentPercent",
            vals[i].indexAssignmentPercent,
            partNumber
          ) !== "red";
        vals[i].passedAllMetrics = passed ? "YES" : "NO";
      }

      const disp = dispositions.find((v) => v.id === vals[i].dispositionID);

      var dt2 = new Date(vals[i].Date);
      const is2023 = new Date(2023, 0).getTime() < dt2.getTime();

      if (disp.color === "green") {
        is2023 && successes++;
        tenantEntry.success++;
      } else if (disp.color === "red") {
        is2023 && failures++;
        tenantEntry.failed++;
      } else if (disp.color === "yellow") {
        is2023 && optimizations++;
        tenantEntry.failedOptimization++;
      } else {
        tenantEntry.notDispositioned++;
        is2023 && notDispositioned++;
      }
    }

    console.log(vals);

    return vals;
  }, [getAccessTokenSilently, dispositions, numMonths]);

  useEffect(() => {
    fetchWithToken(getAccessTokenSilently, "/dispositions").then((resp) =>
      setDispositions(resp)
    );
  }, [getAccessTokenSilently]);

  const cellPrepared = useCallback(
    (e) => {
      if (e.rowType !== "data") return;

      const clr = gradeMetricForRun(
        e.column.name,
        e.value,
        e.data?.reagentPartNumber
      );

      e.cellElement.style.backgroundColor = clr;
      if (!clr) e.cellElement.style.color = "black";
      else e.cellElement.style.color = "white";

      if (e.column.name === "passedAllMetrics") {
        if (e.value === "YES") e.cellElement.style.backgroundColor = "green";
        if (e.value === "NO") e.cellElement.style.backgroundColor = "red";
        e.cellElement.style.color = "white";
      }

      if (e.column.name === "dispositionID") {
        const disp = dispositions.find((v) => v.id === e.data.dispositionID);
        e.cellElement.style.backgroundColor =
          disp.color === "yellow" ? "orange" : disp.color;
        e.cellElement.style.color = "white";
      }
    },
    [dispositions]
  );

  var updateData = useCallback(
    async (key, values) => {
      await fetchWithToken(getAccessTokenSilently, "/run/" + key, {
        method: "PATCH",
        body: JSON.stringify(values),
      });
    },
    [getAccessTokenSilently]
  );

  const refreshOptions = useMemo(() => {
    return {
      icon: "refresh",
      hint: "Refresh",
      text: "",
      stylingMode: "icon",
      onClick: () => {
        if (grid) grid.current.instance.refresh();
      },
    };
  }, []);

  const resetOptions = useMemo(() => {
    return {
      hint: "Reset to default table layout",
      text: "   Reset",
      stylingMode: "text",
      onClick: () => {
        grid.current.instance.state({});
      },
    };
  }, []);

  const spacerOptions = useMemo(() => {
    return {
      hint: "",
      text: "",
      stylingMode: "icon",
      disabled: true,
    };
  }, []);

  var customStore = useMemo(
    () =>
      new CustomStore({
        key: "runID",
        load: loadData,
        update: updateData,
      }),
    [loadData, updateData]
  );


  // The latest datagrid implementation with embedded refresh toolbar icon
  return (
    <>

      <SelectBox
        value={numMonths}
        valueExpr="value"
        displayExpr="name"
        placeholder="Select a value..."
        showClearButton={false}
        dataSource={fields}
        text="Data to query"
        onValueChanged={(v) => {
          setNumMonths(v.value);
          grid.current.instance.getDataSource().reload();
        }}
        style={{ width: "150px" }}
      />
      
      <div id="parentDivList" style={{ margin: 10 }}>
        <DataGrid
          ref={grid}
          className="mygrid"
          dataSource={customStore}
          width="100%"
          height="90vh"
          keyExpr="runID"
          wordWrapEnabled
          showBorders
          showColumnLines
          showRowLines
          allowColumnResizing
          allowColumnReordering
          columnResizingMode="widget"
          columnHidingEnabled
          columnAutoWidth
          columnFixing={colfixing}
          onCellPrepared={cellPrepared}
        >
          <Export
            enabled={true}
            fileName={"CustomerReport"}
            allowExportSelectedData={true}
          />

          <ColumnChooser enabled={true} />
          <GroupPanel visible />
          <SearchPanel visible />
          <Grouping autoExpandAll />
          <FilterRow visible />
          <HeaderFilter visible />
          <Selection mode={"single"} />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey="customerReportTablev10"
          />

          <Toolbar>
            <Item location="after" widget="dxButton" options={refreshOptions} />
            <Item name="exportButton" />
            <Item name="columnChooserButton" />
            <Item name="groupPanel" />
            <Item name="searchPanel" />
            <Item name="saveButton" />
            <Item location="before" widget="dxButton" options={spacerOptions} />
            <Item location="before" widget="dxButton" options={resetOptions} />
          </Toolbar>

          <Column
            dataField="currentlyRunning"
            caption="In Progress"
            allowEditing={false}
          />
          <Column dataField="tenantID" width={150} allowEditing={false} />
          <Column
            dataField="instrumentName"
            caption="Instrument Name"
            dataType="string"
            groupCellTemplate={groupCellTemplate}
            allowEditing={false}
          />
          <Column
            dataField="instrumentNickname"
            dataType="string"
            groupCellTemplate={groupCellTemplate}
            allowEditing={false}
          />
          <Column
            dataField="country"
            dataType="string"
            groupCellTemplate={groupCellTemplate}
            allowEditing={false}
          />
          <Column
            dataField="city"
            dataType="string"
            groupCellTemplate={groupCellTemplate}
            allowEditing={false}
          />
          <Column
            dataField="instrumentSerialNumber"
            caption="Instrument Serial Number"
            dataType="string"
            groupCellTemplate={groupCellTemplate}
            allowEditing={false}
          />
          <Column
        //    fixed
            dataField="runName"
            dataType="string"
            allowEditing={false}
            cellRender={cellRenderRunName}
          />
          <Column dataField="side" allowEditing={false} />
          <Column
            dataField="Date"
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            allowEditing={false}
          />

          <Column
            dataField="lastStatusUpdated"
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            customizeText={customizedDateText}
            allowEditing={false}
          />
          <Column dataField="lastStatus" allowEditing={false} />

          <Column dataField="version" allowEditing={false} />
          <Column dataField="runID" visible={false} allowEditing={false} />
          <Column
            dataField="runFolderName"
            visible={false}
            allowEditing={false}
          />        
          <Column dataField={"recipeValues"} caption="Recipe Bundle" customizeText={getRecipeBundle} />

          <Column dataField="prepType" allowEditing={false} />
          <Column dataField="chemistryVersion" allowEditing={false} />
          <Column dataField="kitType" allowEditing={false} />
          <Column dataField="libraryType" allowEditing={false} />
          <Column dataField="libraryPools" allowEditing={false} />
          <Column dataField="SequencingKit" allowEditing={false} />
          <Column dataField="TargetedReads" allowEditing={false} />
          <Column dataField="readOrder" allowEditing={false} />
          <Column dataField="highDensity" allowEditing={false} />
          <Column dataField="lowDiversity" allowEditing={false} />
          <Column dataField="r1" visible={false} allowEditing={false} />
          <Column dataField="r2" visible={false} allowEditing={false} />
          <Column dataField="i1" visible={false} allowEditing={false} />
          <Column dataField="i2" visible={false} allowEditing={false} />
          <Column dataField="runLength" allowEditing={false} />
          <Column
            dataField="runOrigin"
            dataType="string"
            groupCellTemplate={groupCellTemplate}
          />

          <Column
            dataField="q30"
            allowEditing={false}
            customizeText={customizedText}
          >
            <Format type="percent" precision={2} />
          </Column>
          <Column
            dataField="q40"
            allowEditing={false}
            customizeText={customizedText}
          >
            <Format type="percent" precision={2} />
          </Column>
          <Column
            dataField="q50"
            allowEditing={false}
            customizeText={customizedText}
          >
            <Format type="percent" precision={2} />
          </Column>
          <Column
            dataField="avgQ"
            allowEditing={false}
            customizeText={customizedText}
          >
            <Format type="fixedPoint" precision={1} />
          </Column>
          <Column
            dataField="error"
            allowEditing={false}
            customizeText={customizedText}
          >
            <Format type="percent" precision={4} />
          </Column>
          <Column
            dataField="poloniesRaw"
            caption="Polonies Raw (M)"
            customizeText={customizedText}
            allowEditing={false}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="poloniesPF"
            caption="Polonies PF (M)"
            customizeText={customizedText}
            allowEditing={false}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="runTime"
            caption="Run Time (Hrs)"
            customizeText={customizedText}
            allowEditing={false}
          >
            <Format type="fixedPoint" precision={2} />
          </Column>
          <Column
            dataField="indexAssignmentPercent"
            caption="Index Assignment (%)"
            customizeText={customizedText}
            allowEditing={true}
          >
            <Format type="fixedPoint" precision={2} />
          </Column>
          <Column
            dataField="startTime"
            caption="Polination Started Event"
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            customizeText={customizedDateText}
            allowEditing={false}
          />
          <Column
            dataField="endTime"
            caption="Sequenced Event"
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            customizeText={customizedDateText}
            allowEditing={false}
          />
          <Column dataField="numTiles" visible={false} allowEditing={false} />

          <Column
            dataField="shiftPMG"
            dataType="string"
          />
          <Column
            dataField="flowcellSerialNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="flowcellPartNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="flowcellLotNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="flowcellExpiration"
            dataType={"date"}
            format={"MMM dd yyyy"}
            visible={false}
            allowEditing={false}
          />

          <Column
            dataField="reagentSerialNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="reagentPartNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="reagentLotNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="reagentExpiration"
            dataType={"date"}
            format={"MMM dd yyyy"}
            visible={false}
            allowEditing={false}
          />

          <Column
            dataField="bufferSerialNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="bufferPartNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="bufferLotNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="bufferExpiration"
            dataType={"date"}
            format={"MMM dd yyyy"}
            visible={false}
            allowEditing={false}
          />
          <Column dataField="description" width={300} allowEditing={false} />
          <Column dataField="passedAllMetrics" />

          <Column dataField={"dispositionID"} caption="Disposition" width={250}>
            <Lookup
              dataSource={dispositions}
              valueExpr="id"
              displayExpr="name"
            />
          </Column>

          <Column dataField="isTrainingRun" width={150} />
          <Column dataField="runNotes" width={300} />

          <Column dataField="jiraTicket" width={100} />

          <Summary>
            <GroupItem
              column="instrumentName"
              summaryType="count"
              displayFormat="{0} runs"
            />
          </Summary>

          <Paging defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={pagesizes}
            showInfo={true}
            showNavigationButtons={true}
          />

          <Editing
            mode="cell" //"form" "batch" "popup" "cell"
            allowUpdating={true}
            allowAdding={false}
            allowDeleting={false}
          />
        </DataGrid>
      </div>
    </>
  );
});
